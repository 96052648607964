import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { EventDetailsComponent } from "./eventDetailsComponent";

import { getAccessToken } from "../../apis/apiHelper";
import client from "../../apis/axios";

import {
  EVENTS_API_PATH,
  GOOGLE_CALENDAR_BASE_URL,
} from "../../Constants/appConstants";
import { convertISOToGoogleCalenderTimeFormat } from "../../helpers/date";

export const EventDeatilsConatiner = (props) => {
  const { id } = useParams();

  //handle GA analytics of event schedule
  const handleGaScheduleEvent = useCallback(() => {
    if (typeof window !== "undefined") {
      window.gtag("event", "schedule_event");
    }
  }, []);

  //handle to show event details
  const [eventDetails, setEventDetails] = useState({});

  const { name, description, startDateTime, endDateTime, venueDetails } =
    eventDetails;

  // fetch event details
  const getEventDetails = useCallback(async () => {
    const accessToken = await getAccessToken();

    const response = await client.get(accessToken, `${EVENTS_API_PATH}/${id}`);
    if (response?.success) {
      setEventDetails(response.data.event);
    }
  }, [id]);

  //handle to get all events data
  useEffect(() => {
    getEventDetails();
  }, [getEventDetails, id]);

  // google calendar link
  const eventLink = useMemo(() => {
    const startDate = convertISOToGoogleCalenderTimeFormat(startDateTime);
    const endDate = convertISOToGoogleCalenderTimeFormat(endDateTime);

    return `${GOOGLE_CALENDAR_BASE_URL}/calendar/render?action=TEMPLATE&text=${name}&details=${description}&dates=${startDate}/${endDate}&location=${venueDetails?.name},${venueDetails?.address}`;
  }, [
    description,
    endDateTime,
    name,
    startDateTime,
    venueDetails?.address,
    venueDetails?.name,
  ]);

  // function to handle share with friends
  const shareWithFrieds = useCallback(() => {
    if (typeof window !== "undefined") {
      window.gtag("event", "share_with_friends");
    }
    if (navigator.share) {
      navigator.share({
        text: `Hey checkout this event ${name} at ${venueDetails?.name},${venueDetails?.address}`,
        title: "Curtain Up Festival 2023",
        url: window.location.href,
        icon: `${window.location.origin}/favicon1.png`,
      });
    }
  }, [name, venueDetails?.address, venueDetails?.name]);

  return (
    <EventDetailsComponent
      eventDetails={eventDetails}
      shareWithFrieds={shareWithFrieds}
      eventLink={eventLink}
      handleGaScheduleEvent={handleGaScheduleEvent}
    />
  );
};
