import { Image } from "react-bootstrap";

import {
  getFormattedDate,
  getFormattedTime,
} from "../EventDetails/eventDetailsHelper";

import "./eventsList.scss";

const EventsListComponent = ({
  eventList,
  eventDates,
  activeDate,
  handleEventDetailsPage,
  handleEventFiltration,
  formattedDate,
}) => {
  return (
    <div className="cu-fa-event-list-page d-flex flex-column flex-1 scroll-y">
      <div className="events-date-wrap d-flex flex-column align-items-center justify-content-center p-3 gap-2">
        <div className="event-date-info d-flex gap-3">
          {eventDates?.map(
            ({ day, dayOfMonth, formattedDate, originalDate }, i) => (
              <div
                className="d-flex flex-column align-items-center gap-1"
                key={i}
              >
                <span className="fs-mini text-uppercase text-secondary fw-semibold">
                  {day}
                </span>
                <div
                  className={`event-date-box ${
                    activeDate == dayOfMonth ? "active" : ""
                  } fs-sm fw-semibold rounded-circle d-flex align-items-center justify-content-center`}
                  onClick={() =>
                    handleEventFiltration(
                      originalDate,
                      dayOfMonth,
                      formattedDate
                    )
                  }
                >
                  {dayOfMonth}
                </div>
              </div>
            )
          )}
        </div>
        <p className="fs-xs text-black fw-mediumfw-medium mb-0">
          {formattedDate}
        </p>
      </div>
      <div className="cu-fa-card-list gap-4 px-3">
        {eventList?.map(
          ({ imageURL, name, date, startTime, endTime, venueID, _id }, i) => (
            <div
              key={i}
              className="cu-fa-card p-3 d-flex align-items-center gap-3"
              onClick={() => handleEventDetailsPage(_id)}
            >
              <div className="framed-image framed-image-sm rounded-circle d-flex align-items-center justify-content-center overflow-hidden">
                <Image
                  src={imageURL}
                  alt="framed image"
                  className="w-100 rounded-circle"
                />
              </div>
              <div className="cu-fa-card-details d-flex flex-column flex-1 gap-1">
                <h6 className="fs-sm fw-bold mb-0 text-secondary">{name}</h6>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-1">
                    <i className="bi bi-calendar4-week text-primary fs-xs"></i>
                    <span className="fs-mini text-secondary fw-medium">
                      {getFormattedDate(date)}
                    </span>
                  </div>
                  <div className="d-flex align-items-center gap-1">
                    <i className="bi bi-clock text-primary fs-xs"></i>
                    <span className="fs-mini text-secondary fw-medium">
                      {getFormattedTime(startTime, endTime)}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-1">
                  <i className="bi bi-geo-alt text-primary fs-xs"></i>
                  <span className="fs-mini text-secondary fw-medium text-decoration-underline">
                    {venueID?.name}
                  </span>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default EventsListComponent;
