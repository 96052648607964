import "./legalPage.scss";

export const LegalPageComponent = () => {
  return (
    <div className="cu-fa-legal-page d-flex flex-column flex-1 p-5 scroll-y">
      <h6 className="fs-md fw-semibold text-secondary text-center">
        A Night at a Broadway Show on Prudential Ticket Sweepstakes Official
        Rules
      </h6>
      <h6 className="fs-md fw-semibold text-uppercase text-secondary">
        NO PURCHASE NECESSARY TO ENTER OR WIN. A PURCHASE DOES NOT IMPROVE YOUR
        CHANCES OF WINNING.
      </h6>
      <div>
        <p className="fs-sm fw-medium text-secondary">
          <span className="fw-bold">1. How to Enter.</span> Visit the{" "}
          <span className="fw-bold">“Curtain Up” </span>(“Event”) taking place
          at Times Square in New York City between September 8th-10th , scan the
          QR code at the applicable entry point(s) at the Event, and complete
          the entry form on the designated sweepstakes entry microsite including
          your e-mail address and other requested information. All entries must
          be received no later than 3:00PM local time on that Event Day (as
          defined below).
          <br />
          Limit one (1) entry per person per Event Day. No mechanically
          reproduced entries, alterations, facsimiles or forged entries. All
          entries become the sole and exclusive property of the Sponsor and
          receipt of entries will not be acknowledged or returned. Delivery of
          prizes requires a street address (no P.O. Boxes). Sponsor is not
          responsible for lost, late, illegible, stolen, incomplete, invalid,
          unintelligible or misdirected entries, which will be disqualified, or
          for problems of any kind whether mechanical, human or electronic. Only
          fully completed entry forms are eligible. Proof of submission will not
          be deemed to be proof of receipt by Sponsor. Sponsor not responsible
          for the failure of any person to return entry forms to sweepstakes
          headquarters in a timely fashion.
        </p>
        <p className="fs-sm fw-medium text-secondary">
          <span className="fw-bold"> 2. Start/End Times.</span> Sweepstakes will
          take place during Event hours beginning at{" "}
          <span className="fw-bold">2:00PM</span> local time on September 8th,
          2023 and ending at <span className="fw-bold">3:00PM</span> local time
          on September 10 , 2023. Each day of the Event will be a separate
          “Event Day”.
        </p>
        <p className="fs-sm fw-medium text-secondary">
          <span className="fw-bold">3. Eligibility.</span> Participation open
          only to legal residents of the fifty United States or the District of
          Columbia who are 18 or older as of date of entry. Void where
          prohibited, taxed or restricted by law. Employees, officers and
          directors of The Prudential Insurance Company of America (“Sponsor”)
          and its parent company, subsidiaries, affiliates, partners, dealers,
          advertising and promotion agencies, manufacturers or distributors of
          sweepstakes materials and their immediate families (parents, children,
          siblings, spouse) or members of the same household (whether related or
          not) of such employees/officers/directors are not eligible to enter.
          All federal, state and local laws and regulations apply.
        </p>
        <p className="fs-sm fw-medium text-secondary">
          <span className="fw-bold">4. Random Drawing/Odds.</span> Five winners
          will be selected in a random drawing from all eligible entries
          received during the Event. Odds of winning depend on the number of
          eligible entries received for the drawing. Drawing will be conducted
          by a representative of Sponsor. Winners will be notified via email. By
          entering the Sweepstakes, entrants fully and unconditionally agree to
          be bound by these rules and the decisions of the judges, which will be
          final and binding in all matters relating to the Sweepstakes.
        </p>
        <p className="fs-sm fw-medium text-secondary">
          <span className="fw-bold">5. Prizes.</span> Ten (10) Prizes: The five
          winners will each receive a prize package consisting of two (2)
          tickets to a Broadway production of the winner’s choice and on a date
          selected by the winner, up to a maximum of $500 total. Approximate
          retail value of each Prize: $500.00. <br /> Total approximate retail
          value of all prizes combined: $5,000. In the event that the winner
          selects tickets that are worth less than $500, winner will not receive
          the difference. Prizes are non-transferable. Transportation to and
          from the NYC area will not be provided. No substitutions or cash
          redemptions. In the case of unavailability of any prize, Sponsor
          reserves the right to substitute a prize of equal or greater value.
          Taxes and any unspecified expenses are the responsibility of winners.
          The terms and conditions of the tickets awarded as prizes will govern
          if the event does not take place due to weather, an act of God, threat
          or act of terrorism, civil disturbance, pandemic, epidemic or any
          other reason. Event tickets are subject to certain terms and
          conditions. The Released Entities, and/or any other relevant party,
          reserve the right to remove or to deny entry to any winner or guest
          who engages in a disruptive manner, or with intent to annoy, abuse,
          threaten, or harass any other person at the event. Cash cannot be
          substituted for any ticket prize unless otherwise agreed by the
          Sponsor. Unused tickets cannot be refunded, exchanged for cash or
          applied towards tickets for any subsequent years. No responsibility is
          assumed by the Released Entities for any cancelled, rescheduled,
          delayed, modified, abbreviated or postponed event in whole or in part.
          Any other expenses not specifically listed herein responsibility of
          winner. All prize details are at the sole discretion of the Sponsor.
        </p>
        <p className="fs-sm fw-medium text-secondary">
          <span className="fw-bold">6. Notification.</span> Winners will be
          notified by October 15, 2023. . Winners may be required to sign and
          return, where legal, an Affidavit of Eligibility and
          Liability/Publicity Release within five (5) days of prize
          notification. If any winner is considered a minor in his/her
          jurisdiction of residence, Liability/Publicity Release must be signed
          by his/her parent or legal guardian and such prize will be delivered
          to minor’s parent/legal guardian and awarded in the name of
          parent/legal guardian. If any winner cannot be contacted after first
          notification attempt, if any prize or prize notification is returned
          as undeliverable, or in the event of noncompliance with these
          Sweepstakes rules and requirements, such prize will be forfeited and
          an alternate winner will be selected from all remaining eligible
          entries. Upon prize forfeiture, no compensation will be given. Limit
          one prize per person or household.
        </p>
        <p className="fs-sm fw-medium text-secondary">
          <span className="fw-bold"> 7. Conditions.</span>{" "}
          {`All federal, state
          and local taxes are the sole responsibility of the winners.
          Participation in Sweepstakes and acceptance of prize constitutes each
          winner’s permission for Sponsor to use his/her name, address (city and
          state), likeness, photograph, picture, portrait, voice, biographical
          information and/or any statements made by each winner regarding the
          Sweepstakes or Sponsor for advertising and promotional purposes
          without notice or additional compensation, except where prohibited by
          law. By participating, entrants and winners agree to release and hold
          harmless Sponsor, its advertising and promotion agencies and their
          respective parent companies, subsidiaries, affiliates, partners,
          representatives, agents, successors, assigns, employees, officers and
          directors, from any and all liability, for loss, harm, damage, injury,
          cost or expense whatsoever including without limitation, property
          damage, personal injury and/or death which may occur in connection
          with, preparation for, travel to, or participation in Sweepstakes, or
          possession, acceptance and/or use or misuse of prize or participation
          in any Sweepstakes-related activity and for any claims based on
          publicity rights, defamation or invasion of privacy and merchandise
          delivery. Sponsor is not responsible if Sweepstakes cannot take place
          or if any prize cannot be awarded due to delays or interruptions due
          to acts of God, acts of war, natural disasters, epidemics, pandemics,
          weather or acts of terrorism. Entrants who do not comply with these
          Official Rules, or attempt to interfere with this Sweepstakes in any
          way shall be disqualified.`}
        </p>
        <p className="fs-sm fw-medium text-secondary">
          <span className="fw-bold"> 8. List of Winners.</span>To obtain a list
          of winners, send a self-addressed, stamped envelope by December 13,
          2023 to The Prudential Insurance Company of America, c/o Giovanna
          Moscoso, 213 Washington Street, Newark, NJ 07102.
        </p>
        <p className="fs-sm fw-bold text-secondary">
          9. Sponsor. The Prudential Insurance Company of America, with an
          office and place of business at 655 Broad Street, Newark, NJ 07102,
          USA.
        </p>
      </div>
    </div>
  );
};
