import axios from "axios";

// creating axios client instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Setting Up Deafults Request Headers
axiosInstance.defaults.headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const request = async (url, data, method, token, headersOptions = {}) => {
  const onSuccess = (res) => res.data;
  const onError = (error) => error?.response?.data;

  try {
    const res = await axiosInstance.request({
      url,
      method,
      data,
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
        ...headersOptions,
      },
    });

    return onSuccess(res);
  } catch (error) {
    return onError(error);
  }
};

const _get = async (token, url, headers) =>
  await request(url, null, "get", token, headers);
const _post = async (token, url, data, headers) =>
  await request(url, data, "post", token, headers);
const _put = async (token, url, data, headers) =>
  await request(url, data, "put", token, headers);
const _delete = async (token, url, data, headers) =>
  await request(url, data, "delete", token, headers);

const client = {
  get: _get,
  post: _post,
  put: _put,
  delete: _delete,
};

export default client;
