import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { HomePageComponent } from "./homePageComponent";

import { getAccessToken } from "../../apis/apiHelper";
import client from "../../apis/axios";

import {
  EVENTS_API_PATH,
  EVENT_LIST,
  getApiFormattedDate,
  NEWS_TICKER,
} from "../../Constants/appConstants";

export const HomePageConatiner = () => {
  const navigate = useNavigate();

  const formattedDate = getApiFormattedDate(new Date());

  //handle to store the event list
  const [eventList, setEventList] = useState([]);

  //handle to show news ticker
  const [newsTicker, setNewsTicker] = useState("");

  //handle to go event listing page
  const handleShowAllEvents = useCallback(() => {
    if (typeof window !== "undefined") {
      window.gtag("event", "view_all_events");
    }
    navigate(EVENT_LIST);
  }, [navigate]);

  //handle ga analytics
  const handleInfoCardBtnClick = useCallback((ga_title) => {
    if (typeof window !== "undefined") {
      window.gtag("event", ga_title);
    }
  }, []);

  const handleAppsCardBtnClick = useCallback((gaTitle) => {
    if (typeof window !== "undefined") {
      window.gtag("event", gaTitle);
    }
  }, []);

  //handle to get the data of events
  useEffect(() => {
    const getEventDetails = async () => {
      const accessToken = await getAccessToken();
      const dateFormatter =  new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      });
      const response = await client.get(
        accessToken,
        `${EVENTS_API_PATH}?date=${formattedDate}`
      );

      if (response?.success) {
        const currentTime = getCurrentTime(dateFormatter);
        let eventList = response.data.events;
        let currentEventList = [];
        for(let i = 0 ; i < eventList.length ; i++) {
          if(eventList[i].endTime) {
            if(currentTime < getEventTime(eventList[i], dateFormatter)) {
              currentEventList.push(eventList[i]);
            }
          } else {
            currentEventList.push(eventList[i]);
          } 
          setEventList(currentEventList);
        }
      } else {
        setEventList([]);
      }

      const news = await client.get(accessToken, NEWS_TICKER);
      if (news?.success) {
        setNewsTicker(news.data.newsTicker.text);
      } else {
        setNewsTicker("");
      }

      function getEventTime(event, dateFormatter) {
        const [hours, minutes] = event.endTime.split(':').map(Number);
        const parsedDate = new Date();
        parsedDate.setHours(hours);
        parsedDate.setMinutes(minutes);
        const eventTime = dateFormatter.format(parsedDate);
        return eventTime;
      }

      function getCurrentTime(dateFormatter) {
        const newYorkDate = new Date().toLocaleString('en-US', {
          timeZone: 'America/New_York',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        });
        const [hours, minutes] = newYorkDate.split(':').map(Number);
        const parsedDate = new Date();
        parsedDate.setHours(hours);
        parsedDate.setMinutes(minutes);
        const currentTime = dateFormatter.format(parsedDate);
        return currentTime;
      }
    };
    getEventDetails();
  }, [formattedDate]);

  return (
    <HomePageComponent
      eventList={eventList}
      handleShowAllEvents={handleShowAllEvents}
      newsTicker={newsTicker}
      handleInfoCardBtnClick={handleInfoCardBtnClick}
      handleAppsCardBtnClick={handleAppsCardBtnClick}
    />
  );
};
