import { Image } from "react-bootstrap";

import map from "../../assets/images/CU-Map-23.jpg";

export const FestiveMapComponent = () => {
  return (
    <div className="d-flex flex-column flex-1 scroll-y">
      <Image src={map}  alt="festive map" className="w-100 img-fluid"/>
    </div>
  )
}