export const getDatesOfSeptember = () => {
  const desiredDates = [8, 9, 10];

  // Months are zero-based (0: January, 1: February, ..., 8: September)
  const september = 8;

  const dates = [];

  for (let day = 1; day <= 31; day++) {
    const date = new Date(new Date().getFullYear(), september, day);

    if (
      date.getMonth() === september &&
      desiredDates.includes(date.getDate())
    ) {
      // Get the day from the date
      const day = date.toLocaleString("en-US", {
        weekday: "short", // You can also use 'short' or 'narrow'
      });

      // Get the day of the month (dd) from the date
      const dayOfMonth = date.toLocaleString("en-US", {
        day: "numeric", // Use '2-digits' for leading zeros
      });

      // Get the formatted date
      const formattedDate = date.toLocaleString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      dates.push({ day, dayOfMonth, formattedDate, originalDate: date });
    }
  }
  return dates;
};
