import { useContext, useState } from "react";
import { toast } from "react-hot-toast";

import client from "../../apis/axios.js";
import { HOME_PAGE, BROADWAY_SITE_URL } from "../../Constants/appConstants.js";
import { getAccessToken } from "../../apis/apiHelper.js";

import SweepstakesComponent from "./sweepstakesComponent";
import { useNavigate } from "react-router-dom";
import AppContext from "../../AppContext.js";

export const SweepstakesContainer = () => {
  const navigate = useNavigate();
  
  const [showModal, setShowModal] = useState(false);

  const { firstName, setFirstName } = useContext(AppContext);
  const { lastName, setLastName } = useContext(AppContext);
  const { email, setEmail } = useContext(AppContext);
  const { phoneNumber, setPhoneNumber } = useContext(AppContext);
  const { zipCode, setZipCode} = useContext(AppContext);
  const { emailMarketingOpted, setEmailMarketingOpted } =useContext(AppContext);
  const { phoneNumberMarketingOpted, setPhoneNumberMarketingOpted } =useContext(AppContext);
  const { privacyPolicyAccepted, setPrivacyPolicyAccepted } =useContext(AppContext);

  const handleFormSubmit = async (evt) => {
    if (typeof window !== "undefined") {
      window.gtag("event", "submit_sweeptakes_form");
    }

    // prevent default page refresh behaviour
    evt.preventDefault();

    const token = await getAccessToken();
    // const toastId = toast.loading("Registering...");
    const formData = new FormData(evt.target);

    const payload = {};
    formData.forEach((value, key) => {
      if(value)
      payload[key] = value;
    });

    const response = await client.post(token, "/api/v1/site_users/broadway", {
      ...payload,
      emailMarketingOpted,
      privacyPolicyAccepted,
      phoneNumberMarketingOpted,
    });

    if (response.success) {
      setShowModal(!showModal);
      // toast.success("Registered successfully!", { id: toastId });
      // navigate(HOME_PAGE);
    } else {
      toast.error(response.message || "Failed to register!");
    }
  };

  return (
    <SweepstakesComponent
      firstName={firstName}
      setFirstName={setFirstName}
      lastName={lastName}
      setLastName={setLastName}
      email={email}
      setEmail={setEmail}
      phoneNumber={phoneNumber}
      setPhoneNumber={setPhoneNumber}
      zipCode={zipCode}
      setZipCode={setZipCode}
      handleFormSubmit={handleFormSubmit}
      emailMarketingOpted={emailMarketingOpted}
      setEmailMarketingOpted={setEmailMarketingOpted}
      privacyPolicyAccepted={privacyPolicyAccepted}
      setPrivacyPolicyAccepted={setPrivacyPolicyAccepted}
      phoneNumberMarketingOpted={phoneNumberMarketingOpted}
      setPhoneNumberMarketingOpted={setPhoneNumberMarketingOpted}
      setShowModal={setShowModal}
      showModal={showModal}
    />
  );
};
