import { Link } from "react-router-dom";
import { Button, Image, Nav } from "react-bootstrap";

import { EventCardConatiner } from "../../SharedComponents/eventCard/eventCardContainer";

import { APPS_CARD, INFO_CARD } from "./homePageHelper";

import logo from "./Prudential-Financial-logo for browser.png";
import "./homePage.scss";

export const HomePageComponent = ({
  eventList,
  handleShowAllEvents,
  newsTicker,
  handleInfoCardBtnClick,
  handleAppsCardBtnClick,
}) => {
  return (
    <div className="cu-fa-homepage d-flex flex-column flex-1 scroll-y">
      <div className="d-flex upcoming-events-wrap">
        <div className="bg-primary text-uppercase fs-xs fw-bold text-white cu-fa-arrow-box position-relative d-flex align-items-center flex-column next-up">
          <span>Next </span>
          <span>UP</span>
        </div>
        <div className="d-flex gap-2 flex-1 align-items-center p-2">
          <img
            className="sliding-text news-ticker-icon mx-2"
            src={logo}
            alt="No img found"
          />
          <p className="sliding-text fs-sm fw-semibold text-secondary mb-0 text-nowrap">
            {newsTicker}
          </p>
          <img
            className="sliding-text news-ticker-icon mx-2"
            src={logo}
            alt="No img found"
          />
          <p className="sliding-text fs-sm fw-semibold text-secondary mb-0 text-nowrap">
          {newsTicker}
          </p>
          <img
            className="sliding-text news-ticker-icon mx-2"
            src={logo}
            alt="No img found"
          />
          <p className="sliding-text fs-sm fw-semibold text-secondary mb-0 text-nowrap">
            {newsTicker}
          </p>
          <img
            className="sliding-text news-ticker-icon mx-2"
            src={logo}
            alt="No img found"
          />
          <p className="sliding-text fs-sm fw-semibold text-secondary mb-0 text-nowrap">
          {newsTicker}
          </p>
          
        </div>
      </div>
      <div className="cu-fa-card-list info-card gap-4 px-4">
        {INFO_CARD?.map(
          (
            {
              cardReverse,
              size,
              cardImg,
              textCardWhite,
              cardTitle,
              cardInfo,
              cardBtnText,
              link,
              ga_title,
            },
            i
          ) => (
            <div
              key={i}
              className={`cu-fa-card ${
                cardReverse ? "cu-fa-reverse-card" : ""
              } py-3 d-flex align-items-center gap-3`}
            >
              <div
                className={`framed-image rounded-circle d-flex align-items-center justify-content-center overflow-hidden ${
                  size === "lg" ? "framed-image-lg" : ""
                }`}
              >
                <Image
                  src={cardImg}
                  alt="framed image"
                  className="w-100 rounded-circle"
                />
              </div>
              <div className="cu-fa-card-details flex-1">
                <h6
                  className={`fw-bold mb-0 ${
                    textCardWhite ? "text-white" : "text-secondary"
                  }`}
                >
                  {cardTitle}
                </h6>
                <p
                  className={`fs-xs fw-medium mb-2 ${
                    textCardWhite ? "text-white" : "text-secondary"
                  }`}
                >
                  {cardInfo}
                </p>

                <Link
                  to={link}
                  onClick={() => handleInfoCardBtnClick(ga_title)}
                >
                  <Button
                    className="text-white fs-mini text-uppercase fw-semibold rounded-pill px-3"
                    size="sm"
                  >
                    {cardBtnText}
                  </Button>
                </Link>
              </div>
            </div>
          )
        )}
      </div>
      <div className="event-card p-4">
        <div className="d-flex align-items-center justify-content-between">
          <h6 className="mb-0 fw-bold">TODAY’S EVENTS AND SCHEDULE</h6>
          <Button
            className="text-uppercase fs-mini text-white fw-semibold rounded-pill py-2 px-3"
            onClick={handleShowAllEvents}
          >
            View All Events
          </Button>
        </div>
        <div className="cu-fa-card-list">
          <EventCardConatiner eventList={eventList} />
        </div>
      </div>
      <div className="cu-fa-card-list cu-fa-card-blue p-4 bg-secondary">
        {APPS_CARD?.map(
          (
            {
              cardReverse,
              size,
              cardImg,
              textCardWhite,
              cardTitle,
              cardInfo,
              cardBtnText,
              link,
              isExternalLink,
              ga_title,
            },
            i
          ) => (
            <div
              key={i}
              className={`cu-fa-card ${
                cardReverse ? "cu-fa-reverse-card" : ""
              } py-3 d-flex align-items-center gap-3`}
            >
              <div
                className={`framed-image rounded-circle d-flex align-items-center justify-content-center overflow-hidden ${
                  size === "lg" ? "framed-image-lg" : ""
                }`}
              >
                <Image
                  src={cardImg}
                  alt="framed image"
                  className="w-100 rounded-circle"
                />
              </div>
              <div className="cu-fa-card-details flex-1">
                <h6
                  className={`fw-bold mb-0 ${
                    textCardWhite ? "text-white" : "text-secondary"
                  }`}
                >
                  {cardTitle}
                </h6>
                <p
                  className={`fs-xs fw-medium mb-2 ${
                    textCardWhite ? "text-white" : "text-secondary"
                  }`}
                >
                  {cardInfo}
                </p>
                {isExternalLink ? (
                  <Nav.Link
                    href={link}
                    onClick={() => handleAppsCardBtnClick(ga_title)}
                  >
                    <Button
                      className="text-white fs-mini text-uppercase fw-semibold rounded-pill px-3"
                      size="sm"
                    >
                      {cardBtnText}
                    </Button>
                  </Nav.Link>
                ) : (
                  <Link
                    to={link}
                    onClick={() => handleAppsCardBtnClick(ga_title)}
                  >
                    <Button
                      className="text-white fs-mini text-uppercase fw-semibold rounded-pill px-3"
                      size="sm"
                    >
                      {cardBtnText}
                    </Button>
                  </Link>
                )}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};
