import { useCallback, useEffect, useState } from "react";

import EventsListComponent from "./eventsListComponent";

import client from "../../apis/axios";
import { getAccessToken } from "../../apis/apiHelper";
import {
  EIGHT,
  EVENT_DETAILS,
  formatDateToYYYYMMDD,
  getDefaultEventDate,
  getFullDateFormat,
} from "../../Constants/appConstants";
import { getDatesOfSeptember } from "./eventHelpers";
import { EVENTS_API_PATH } from "../../Constants/appConstants";
import { useNavigate } from "react-router-dom";

export const EventsListConatiner = () => {
  const navigate = useNavigate();

  //handle to store the event list
  const [eventList, setEventList] = useState([]);

  //handle to show active selected date
  const [activeDate, setActiveDate] = useState(EIGHT);

  //handle to show formmated date

  const [defaultEventDate, setDefaultEventDate] = useState(
    getDefaultEventDate(activeDate)
  );

  const [formattedDate, setFormattedDate] = useState(
    getFullDateFormat(defaultEventDate)
  );
  //handle to go event details page
  const handleEventDetailsPage = useCallback(
    (id) => {
      if (typeof window !== "undefined") {
        window.gtag("event", "event_details");
      }
      navigate(`${EVENT_DETAILS.replace(":id", id)}`);
    },
    [navigate]
  );

  //handle to show filtered events
  const handleEventFiltration = (originalDate, dayOfMonth, formattedDate) => {
    if (typeof window !== "undefined") {
      window.gtag("event", "selected_date_events");
    }
    setDefaultEventDate(formatDateToYYYYMMDD(formattedDate));
    setActiveDate(dayOfMonth);
    setFormattedDate(formattedDate);
  };

  //handle to get all events data
  useEffect(() => {
    const getEventDetails = async () => {
      const accessToken = await getAccessToken();
      const currentDate = parseInt(defaultEventDate.split("/")[2]);
      setActiveDate(currentDate.toString());
      const response = await client.get(
        accessToken,
        `${EVENTS_API_PATH}?date=${defaultEventDate}`
      );

      if (response?.success) {
        setEventList(response.data.events);
      } else {
        setEventList([]);
      }
    };
    getEventDetails();
  }, [defaultEventDate]);

  return (
    <EventsListComponent
      eventList={eventList}
      activeDate={activeDate}
      eventDates={getDatesOfSeptember()}
      handleEventDetailsPage={handleEventDetailsPage}
      handleEventFiltration={handleEventFiltration}
      formattedDate={formattedDate}
    />
  );
};
