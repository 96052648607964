export const getFormattedDate = (dateString = "") => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let date = new Date(dateString)
  date = date.toLocaleString('en-US', { timeZone: 'UTC' });
  date = new Date(date)
  const dayOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][
    date.getDay()
  ];
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  if (dayOfWeek && month && day && year) {
    return `${dayOfWeek}, ${month} ${day}, ${year}`;
  } else {
    return "";
  }
};

export const getFormattedTime = (startTime, endTime) => {
  if (!startTime || !endTime) {
    return ""; // Or you can return a default value if startTime is missing
  }

  // get starTime in AM/PM
  const [startHour, startMinute] = startTime.split(":");
  let startPeriod = "AM";
  let startHour12 = parseInt(startHour, 10);
  if (startHour12 >= 12) {
    startPeriod = "PM";
    if (startHour12 > 12) {
      startHour12 -= 12;
    }
  } else if (startHour12 === 0) {
    startHour12 = 12; // Midnight
  }

  //get endTime in AM/PM
  const [endHour, endMinute] = endTime.split(":");
  let endHour12 = parseInt(endHour, 10);
  let endPeriod = "AM";
  if (endHour12 >= 12) {
    endPeriod = "PM";
    if (endHour12 > 12) {
      endHour12 -= 12;
    }
  } else if (endHour12 === 0) {
    endHour12 = 12; // Midnight
  }

  return `${startHour12}:${startMinute} ${startPeriod} - ${endHour12}:${endMinute} ${endPeriod}`;
};

export const getFormattedStartTime = (startTime) => {
  if (!startTime) {
    return ""; // Or you can return a default value if startTime is missing
  }

  // get starTime in AM/PM
  const [startHour, startMinute] = startTime.split(":");
  let startPeriod = "AM";
  let startHour12 = parseInt(startHour, 10);
  if (startHour12 >= 12) {
    startPeriod = "PM";
    if (startHour12 > 12) {
      startHour12 -= 12;
    }
  } else if (startHour12 === 0) {
    startHour12 = 12; // Midnight
  }

  return `${startHour12}:${startMinute} ${startPeriod}`;
};
