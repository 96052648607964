/* eslint-disable react/jsx-no-target-blank */
import { Button, Image, Modal } from "react-bootstrap";

import { getFormattedDate, getFormattedTime } from "./eventDetailsHelper";

import "./eventDetails.scss";
import { useState } from "react";

export const EventDetailsComponent = ({
  eventDetails,
  shareWithFrieds,
  eventLink,
  handleGaScheduleEvent,
}) => {
  const {
    name,
    description,
    imageURL,
    venueDetails,
    date,
    startTime,
    endTime,
  } = eventDetails;

  const [showModal, setShowModal] = useState(false);

  const toggleShowModal = () => setShowModal(!showModal);

  return (
    <div className="cu-fa-event-deatils-page d-flex flex-column flex-1 scroll-y">
      <div className="cu-fa-event-details-section">
        <div className="cu-fa-card pb-3 d-flex align-items-center gap-3">
          <div className="framed-image framed-image-lg rounded-circle d-flex align-items-center justify-content-center overflow-hidden">
            <Image
              src={imageURL}
              alt="framed image"
              className="w-100 rounded-circle"
            />
          </div>
          <div className="cu-fa-card-details d-flex flex-column flex-1 gap-1">
            <h6 className="fs-sm fw-bold mb-0 text-secondary">{name}</h6>
            {/* <p className="fs-sm fw-bold mb-0 text-secondary overflow-hidden text-truncate-one-line">
              {description || ""}
            </p> */}
            <div className="d-flex align-items-center gap-1">
              <i className="bi bi-calendar4-week text-primary fs-xs"></i>
              <span className="fs-xs fw-medium text-secondary">
                {getFormattedDate(date) || ""}
              </span>
            </div>
            <div className="d-flex align-items-center gap-1">
              <i className="bi bi-geo-alt text-primary fs-xs"></i>
              <span className="fs-xs text-secondary fw-medium text-decoration-underline">
                {venueDetails?.address || ""}
              </span>
            </div>
            <div className="d-flex align-items-center gap-1">
              <i className="bi bi-clock text-primary fs-xs"></i>
              <span className="fs-xs fw-medium text-secondary">
                {getFormattedTime(startTime, endTime) || ""}
              </span>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-center gap-2 py-2">
          <Button
            variant="outline-primary"
            className="d-flex align-items-center gap-2 text-secondary border-2 rounded-pill py-1 px-3"
          >
            <i className="bi bi-upload"></i>
            <span
              onClick={shareWithFrieds}
              className="fs-xs fw-semibold text-uppercase"
            >
              Share
            </span>
          </Button>
          <Button
            variant="primary"
            className="d-flex align-items-center gap-2 text-white rounded-pill py-1 px-3"
          >
            <i className="bi bi-calendar-event"></i>
            <a
              className="fs-xs fw-semibold text-uppercase text-white"
              href={eventLink}
              target="_blank"
              onClick={handleGaScheduleEvent}
            >
              Schedule event
            </a>
          </Button>
        </div>
        <hr className="cu-separator" />
        <div className="event-details-box">
          <h6 className="text-secondary fw-bold">Event Description</h6>
          <p className="fs-xs mb-0">{description || ""}</p>
        </div>
        <hr className="cu-separator" />
        <div className="event-details-box">
          <h6 className="text-secondary fw-bold">Venue Details</h6>
          <div className="venue-map-wrap h-100 mb-5" onClick={toggleShowModal}>
            <Image
              src={venueDetails?.mapImageURL}
              alt="venue map"
              className="w-100 h-100"
            />
          </div>

          <div className="d-flex align-items-center justify-content-center gap-1">
            <i className="bi bi-geo-alt text-primary"></i>
            <span className="fs-xs text-secondary fw-medium text-decoration-underline">
              {venueDetails?.address}
            </span>
          </div>
        </div>
      </div>
      <Modal
        className="cu-modal p-2"
        show={showModal}
        onHide={toggleShowModal}
        centered
        size="md"
      >
        <Modal.Header closeButton className="border-0 p-3"></Modal.Header>
        <Modal.Body className="fs-6 text-center fw-semibold p-2 scroll-x">
          <Image
            src={venueDetails?.mapImageURL}
            height={250}
            alt="map"
            className="cu-modal-img"
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
