import React from "react";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";

import reportWebVitals from "./reportWebVitals";
import { RoutesConfig } from "./RoutesConfig";

import "./assets/stylesheets/main.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <main className="cu-fa-main-wrap d-flex flex-column flex-1 align-items-center scroll-y">
      <div className="cu-fa-content-section d-flex flex-column flex-1 scroll-y position-relative">
        <div className="cu-fa-content-body d-flex flex-column flex-1 scroll-y">
          <RoutesConfig />
          <Toaster />
        </div>
      </div>
    </main>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
