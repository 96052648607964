import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ROUTES } from "./Constants/appConstants";
import { HeaderContainer } from "./SharedComponents/headerContainer";
import { useState } from "react";
import AppContext from "./AppContext";

export const RoutesConfig = () => {
  //const [previousPage, setPreviousPage] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [emailMarketingOpted, setEmailMarketingOpted] = useState(false);
  const [phoneNumberMarketingOpted, setPhoneNumberMarketingOpted] =
    useState(false);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  return (
    
    <AppContext.Provider value={{ firstName, setFirstName ,lastName, setLastName ,email, setEmail,phoneNumber, setPhoneNumber ,zipCode, setZipCode ,
      emailMarketingOpted, setEmailMarketingOpted ,phoneNumberMarketingOpted, setPhoneNumberMarketingOpted,privacyPolicyAccepted, setPrivacyPolicyAccepted}} >
    <BrowserRouter>
      <HeaderContainer />
      <Routes>
        {ROUTES.map((route, i) => (
          <Route
            key={i}
            exact
            path={route.path}
            element={route.element}
          ></Route>
        ))}
      </Routes>
    </BrowserRouter>
    </AppContext.Provider>
  );
};
