//Menu redirection constant
export const EVENTS_SCHEDULAR = `${process.env.REACT_APP_BASE_URL}/events-list`;
export const FESTIVAL_MAP = `${process.env.REACT_APP_BASE_URL}/festive-map`;
export const BROADWAY = `${process.env.REACT_APP_BASE_URL}/form`;
export const VISIT_PLAYBILL = "https://www.playbill.com";
export const STAGES_ON_BROADWAY = "https://prudentialstagesonbroadway.com";
export const PIANO_STAGE = "http://prudentialbroadwaytrivia.com";
export const PLAYBILL_SELFIE = `${process.env.REACT_APP_BASE_URL}/staronplaybill`;
export const TIME_SQUARE = "https://www.timessquarenyc.org/curtain-up";
export const LEGAL = "/legal";
export const TERM = "/term";
//Home page constant
export const ENTER_NOW = `${process.env.REACT_APP_BASE_URL}/form`;
export const LEGAL_PATH = `${process.env.REACT_APP_BASE_URL}/legal`;
