import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FESTIVE_MAP } from "../Constants/appConstants";

import HeaderComponent from "./Header/headerComponent";

export const HeaderContainer = () => {
  const navigate = useNavigate();

  let currentPath = window.location.pathname;

  //handle to show or hide menu bar
  const [showNav, setShowNav] = useState(false);

  const handleToggle = useCallback(() => {
    setShowNav((state) => !state);
  }, []);

  
  const handleToGoBack = () => {
    setShowNav(false);
    navigate(-1);
  };

  //handle ga analytics
  const handleMenuClick = (ga_title) => {
    setShowNav(false);
    if (typeof window !== "undefined") {
      window.gtag("event", ga_title);
    }
  };

  const handleShowMap = useCallback(() => {
    if (typeof window !== "undefined") {
      window.gtag("event", "show_map");
    }
    navigate(FESTIVE_MAP);
  }, [navigate]);

  useEffect(() => {
    setShowNav(false);
  }, []);

  return (
    <HeaderComponent
      handleToggle={handleToggle}
      showNav={showNav}
      currentPath={currentPath}
      handleToGoBack={handleToGoBack}
      handleShowMap={handleShowMap}
      handleMenuClick={handleMenuClick}
    />
  );
};
