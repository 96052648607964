import { Image, Button } from "react-bootstrap";
import PropTypes from "prop-types";

import "./eventCard.scss";
import { getFormattedStartTime } from "../../Modules/EventDetails/eventDetailsHelper";

const EventCardComponent = ({ eventList, handleShowEventDetails }) => {
  return (
    <>
      {eventList?.map(
        ({ imageURL, name, description, _id, venueID, startTime }, i) => (
          <div
            key={i}
            className="cu-fa-card py-3 d-flex align-items-center gap-3"
          >
            <div
              className="framed-image rounded-circle d-flex align-items-center justify-content-center overflow-hidden 
              framed-image-lg"
            >
              <Image
                src={imageURL}
                alt="framed image"
                className="w-100 rounded-circle"
              />
            </div>
            <div className="cu-fa-card-details flex-1">
              <h6 className="fw-bold mb-0 text-secondary">{name || ""}</h6>
              {/* <p className="fw-bold fw-medium mb-1 text-secondary text-truncate cu-fa-card-details overflow-hidden">
                {description || ""}
              </p> */}
              <p className="fs-xs fw-medium mb-0 text-secondary text-truncate cu-fa-card-details overflow-hidden">
                {`at ${getFormattedStartTime(startTime) || ""}`}
              </p>
              <p className="fs-xs fw-medium mb-2 text-secondary text-truncate cu-fa-card-details overflow-hidden">
                {`on the ${venueID.name}` || ""}
              </p>
              <Button
                className="text-white fs-mini text-uppercase fw-semibold rounded-pill px-3"
                size="sm"
                onClick={() => handleShowEventDetails(_id)}
              >
                View event
              </Button>
            </div>
          </div>
        )
      )}
    </>
  );
};

EventCardComponent.propTypes = {
  size: PropTypes.oneOf(["lg", ""]),
};
export default EventCardComponent;
