import {
  BROADWAY,
  ENTER_NOW,
  EVENTS_SCHEDULAR,
  FESTIVAL_MAP,
  LEGAL,
  PIANO_STAGE,
  PLAYBILL_SELFIE,
  STAGES_ON_BROADWAY,
  TIME_SQUARE,
  VISIT_PLAYBILL,
} from "../../Constants/linkConstants";

import event21 from "../../assets/images/event2.1.png";
import event22 from "../../assets/images/event2.2.png";
import event23 from "../../assets/images/event2.3.jpg";
import event24 from "../../assets/images/event2.4.png";
import ticketImg from "../../assets/images/tickets.png";
import playbillImg from "../../assets/images/playbill.png";
export const MENU_LIST = [
  {
    icon: "bi bi-clock",
    title: "EVENTS AND SCHEDULE",
    link: EVENTS_SCHEDULAR,
    ga_title: "view_all_events",
  },
  {
    icon: "bi bi-map",
    title: "Festival map",
    link: FESTIVAL_MAP,
    ga_title: "show_map",
  },
  {
    icon: "bi bi-box-arrow-up-right",
    title: "WIN THEATER TICKETS",
    link: BROADWAY,
    ga_title: "broadway_sweeptakes",
  },
  {
    icon: "bi bi-box-arrow-up-right",
    title: "Visit Playbill.com",
    link: VISIT_PLAYBILL,
    ga_title: "visit_playbill",
  },
  {
    icon: "bi bi-box-arrow-up-right",
    title: "Stages on Broadway",
    link: STAGES_ON_BROADWAY,
    ga_title: "stages_on_broadway",
  },
  {
    icon: "bi bi-box-arrow-up-right",
    title: "TRIVIA FOR TIX",
    link: PIANO_STAGE,
    ga_title: "trivia_play_now",
  },
  {
    icon: "bi bi-box-arrow-up-right",
    title: "PRUDENTIAL SELFIE GENERATOR",
    link: PLAYBILL_SELFIE,
    ga_title: "visit_playbill_selfie",
  },
  {
    icon: "bi bi-box-arrow-up-right",
    title: "SHOP TIMES SQUARE",
    link: TIME_SQUARE,
    ga_title: "time_square_deals",
  },
  {
    icon: "bi bi-shield-exclamation",
    title: "Legal",
    link: LEGAL,
    ga_title: "legal_document",
  },
];

export const APPS_CARD = [
  {
    cardImg: event21,
    cardTitle: "See a star in the making",
    cardInfo:
      "Brittney Johnson hosts Prudential Stages on Broadway, where four singing sensations compete for a chance to win $10,000.",
    cardReverse: false,
    cardBtnText: "Learn more",
    textCardWhite: true,
    link: STAGES_ON_BROADWAY,
    ga_title: "see_the_winners",
  },
  {
    cardImg: event22,
    cardTitle: "Think you know Broadway?",
    cardInfo:
      "Put your love of theatre to the test. You could win two tickets to an upcoming performance!",
    cardReverse: true,
    cardBtnText: "Get started",
    textCardWhite: true,
    link: PIANO_STAGE,
    ga_title: "trivia_play_now",
  },
  {
    cardImg: event23,
    cardTitle: "See yourself on a Playbill cover",
    cardInfo:
      "Strike a pose, take a selfie, be the star on a Playbill cover, and light-up Prudential’s Times Square billboard!",
    cardReverse: false,
    cardBtnText: "Get started",
    textCardWhite: true,
    link: PLAYBILL_SELFIE,
    isExternalLink: true,
    ga_title: "visit_playbill_selfie",
  },
  {
    cardImg: event24,
    cardTitle: "Shop Times Square",
    cardInfo: "Get exclusive Times Square deals and discounts and support local businesses.",
    cardReverse: true,
    cardBtnText: "Learn more",
    textCardWhite: true,
    link: TIME_SQUARE,
    ga_title: "time_square_deals",
  },
];

export const INFO_CARD = [
  {
    cardImg: ticketImg,
    cardTitle: "WIN FREE THEATER TICKETS",
    cardInfo:
      "Enter now for your chance to win (2) two tickets to your favorite theatre shows.",
    cardReverse: false,
    cardBtnText: "Enter Now",
    textCardWhite: false,
    link: BROADWAY,
    ga_title: "broadway_sweeptakes",
  },
  {
    cardImg: playbillImg,
    cardTitle: "Playbill—#1 Broadway news source",
    cardInfo: "The theatre-lovers go-to for the latest news, interviews, photos, videos, and more.",
    cardReverse: true,
    cardBtnText: "VISIT PLAYBILL",
    textCardWhite: false,
    link: VISIT_PLAYBILL,
    ga_title: "visit_playbill",
  },
];
