/* eslint-disable react/jsx-no-target-blank */
import {
  Form,
  InputGroup,
  Button,
  FormGroup,
  Modal,
  ModalFooter,
} from "react-bootstrap";

import { LEGAL, LEGAL_PATH, TERM } from "../../Constants/linkConstants";

import { useNavigate } from "react-router-dom";

import "./sweepstakes.scss";

const SweepstakesComponent = ({
  handleFormSubmit,
  firstName, 
  setFirstName,
  lastName, 
  setLastName,
  email, 
  setEmail,
  zipCode, 
  setZipCode,
  phoneNumber, 
  setPhoneNumber,
  emailMarketingOpted,
  phoneNumberMarketingOpted,
  privacyPolicyAccepted,
  setEmailMarketingOpted,
  setPhoneNumberMarketingOpted,
  setPrivacyPolicyAccepted,
  showModal,
  setShowModal,
}) => {
 // const [phoneNumber, setPhoneNumber] = useState("");
  //const [zipCode, setZipCode] = useState("");
  const navigate = useNavigate();

  const handleGoToHome = () => {
    navigate("/");
  };

  const handleShowLegalDoc = () => {
    if (typeof window !== "undefined") {
      window.gtag("event", "legal_document");
    }
    navigate(LEGAL)
  };

  const handleShowTerm = () => {
    if (typeof window !== "undefined") {
      window.gtag("event", "legal_document");
    }
    navigate(TERM)
  };
  
  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (/^[0-9+]*$/.test(value)) {
      setPhoneNumber(value);
    }
  }

  const handleZipCodeChange = (e) => {
    const value = e.target.value;
    if (/^[0-9]*$/.test(value)) {
      setZipCode(value);
    }
  }

  return (
    <div className="cu-fa-event-form d-flex flex-column flex-1 scroll-y">
      <div className="sweepstakes-banner d-flex align-items-center justify-content-center p-5">
        <h4 className="text-white fw-semibold text-center mb-0">
          Enter for your chance to win theatre tickets!
        </h4>
      </div>
      <div className="sweepstakes-form px-4">
        <Form
          className="ca-fa-form d-grid gap-2 p-3"
          onSubmit={handleFormSubmit}
        >
          <Form.Group>
            <Form.Label
              htmlFor="firstName"
              className="fs-sm fw-semibold mb-1 text-secondary"
            >
              First Name
            </Form.Label>
            <InputGroup className="bg-white">
              <InputGroup.Text
                id="firstName"
                className="bg-transparent border-0"
              >
                <i className="bi bi-person text-primary fs-5"></i>
              </InputGroup.Text>
              <Form.Control
                name="firstName"
                placeholder="Enter Here"
                aria-label="Enter Here"
                aria-describedby="firstName"
                className="fs-sm fw-medium border-0 px-2"
                value={firstName}
                onChange={(evt) => setFirstName(evt.target.value)}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <Form.Label
              htmlFor="lastName"
              className="fs-sm fw-semibold mb-1 text-secondary"
            >
              Last Name
            </Form.Label>
            <InputGroup className="bg-white">
              <InputGroup.Text
                id="lastName"
                className="bg-transparent border-0"
              >
                <i className="bi bi-person text-primary fs-5"></i>
              </InputGroup.Text>
              <Form.Control
                name="lastName"
                placeholder="Enter Here"
                aria-label="Enter Here"
                aria-describedby="lastName"
                className="fs-sm fw-medium border-0 px-2"
                value={lastName}
                onChange={(evt) => setLastName(evt.target.value)}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <Form.Label
              htmlFor="email"
              className="fs-sm fw-semibold mb-1 text-secondary"
            >
              Email
            </Form.Label>
            <InputGroup className="bg-white">
              <InputGroup.Text id="email" className="bg-transparent border-0">
                <i className="bi bi-envelope text-primary fs-5"></i>
              </InputGroup.Text>
              <Form.Control
                name="email"
                type="email"
                placeholder="Enter Here"
                aria-label="Enter Here"
                aria-describedby="email"
                className="fs-sm fw-medium border-0 px-2"
                value={email}
                onChange={(evt) => setEmail(evt.target.value)}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <Form.Label
              htmlFor="phone"
              className="fs-sm fw-semibold mb-1 text-secondary"
            >
              Phone
            </Form.Label>
            <InputGroup className="bg-white">
              <InputGroup.Text id="phone" className="bg-transparent border-0">
                <i className="bi bi-phone text-primary fs-5"></i>
              </InputGroup.Text>
              <Form.Control
                name="phoneNumber"
                type="tel"
                placeholder="Enter Here"
                aria-label="Enter Here"
                aria-describedby="phone"
                className="fs-sm fw-medium border-0 px-2"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <Form.Label
              htmlFor="zipCode"
              className="fs-sm fw-semibold mb-1 text-secondary"
            >
              Zip Code
            </Form.Label>
            <InputGroup className="bg-white">
              <InputGroup.Text id="zipCode" className="bg-transparent border-0">
                <i className="bi bi-geo-alt text-primary fs-5"></i>
              </InputGroup.Text>
              <Form.Control
                name="zipCode"
                type="tel"
                placeholder="Enter Here"
                aria-label="Enter Here"
                aria-describedby="zipCode"
                className="fs-sm fw-medium border-0 px-2"
                value={zipCode}
                onChange={handleZipCodeChange}
              />
            </InputGroup>
          </Form.Group>
          <FormGroup className="d-flex flex-column gap-2 px-5 mx-auto py-3">
            <Form.Check
              name="emailMarketingOpted"
              type="checkbox"
              value={emailMarketingOpted}
              checked={emailMarketingOpted}
              onChange={() => {
                setEmailMarketingOpted(!emailMarketingOpted);
                if (typeof window !== "undefined") {
                  window.gtag("event", "receive_emails");
                }
              }}
              label="Yes, I want to subscribe to receive Prudential emails"
              className="chk-label fs-sm fw-medium"
            />
            <Form.Check
              name="privacyPolicyAccepted"
              type="checkbox"
              checked={privacyPolicyAccepted}
              value={privacyPolicyAccepted}
              onChange={() => {
                setPrivacyPolicyAccepted(!privacyPolicyAccepted);
                if (typeof window !== "undefined") {
                  window.gtag("event", "agree_terms");
                }
              }}
              label={
                <span>
                  Yes, I agree to the contest{" "}
                  <a
                    //href={LEGAL_PATH}
                    className="text-blue"
                    target="_blank"
                    onClick={handleShowLegalDoc}
                  >
                    {" "}
                    terms and conditions
                  </a>
                </span>
              }
              className="chk-label fs-sm fw-medium"
            />
            <Form.Check
              name="phoneNumberMarketingOpted"
              type="checkbox"
              value={phoneNumberMarketingOpted}
              onChange={() => {
                setPhoneNumberMarketingOpted(!phoneNumberMarketingOpted);
              }}
              label={
                <span>
                  Yes, I'd like to be contacted by a Prudential financial advisor.*See{" "}
                  <a
                    //href={LEGAL_PATH}
                    className="text-blue"
                    target="_blank"
                    onClick={handleShowTerm}
                  >
                    {" "}
                    Terms
                  </a>
                </span>
              }
              className="fs-sm fw-medium"
            />
            <p className="fs-sm fw-medium mx-4 mt-4">
              *Standard Carrier rates for calls, text, SMS, and/or MMS may apply.
              Please check with your carrier about your rate plan for questions
              regarding your cellular plan charges.
            </p>
          </FormGroup>
          <Button
            type="submit"
            disabled={!privacyPolicyAccepted}
            variant="primary"
            className="fs-xs fw-semibold text-white text-uppercase rounded-pill py-2 px-5 mx-auto"
          >
            Submit
          </Button>
        </Form>
      </div>
      <Modal
        className="cu-modal cu-submit-modal p-2"
        show={showModal}
        onHide={showModal}
        centered
        size="sm"
      >
        <Modal.Body className="text-center">
          <p className="fs-6 fw-semibold mb-0">Thank you for entering!</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="fs-xs fw-semibold text-white text-uppercase rounded-pill py-2 px-5 mx-auto"
            onClick={handleGoToHome}
          >
            Go to Home
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SweepstakesComponent;
