import { EventDeatilsConatiner } from "../Modules/EventDetails/eventDetailsContainer";
import { EventsListConatiner } from "../Modules/EventsList/eventsListContainer";
import { FestiveMapComponent } from "../Modules/FestiveMap/festiveMapComponent";
import { HomePageConatiner } from "../Modules/HomePage/homePageContainer";
import { SweepstakesContainer } from "../Modules/Sweepstakes/sweepstakesConatiner";
import { LegalPageComponent } from "../Modules/legalPage/LegalPageComponent";
import { Term } from "../Modules/legalPage/term";
import { TERM } from "./linkConstants";

//Event date constant
export const EIGHT = 8;
export const NINE = 9;
export const TEN = 10;

//Component constant
export const HOME_PAGE = "/";
export const LEGAL_PAGE = "/legal";
export const EVENT_LIST = "/events-list";
export const EVENT_DETAILS = "/event-details/:id";
export const FORM = "/form";
export const FESTIVE_MAP = "/festive-map";

export const ROUTES = [
  {
    path: HOME_PAGE,
    element: <HomePageConatiner />,
  },
  {
    path: LEGAL_PAGE,
    element: <LegalPageComponent />,
  },
  {
    path: EVENT_LIST,
    element: <EventsListConatiner />,
  },

  {
    path: EVENT_DETAILS,
    element: <EventDeatilsConatiner />,
  },
  {
    path: FORM,
    element: <SweepstakesContainer />,
  },
  {
    path: FESTIVE_MAP,
    element: <FestiveMapComponent />,
  },
  {
    path: TERM,
    element: <Term />,
  },
];

export const getApiFormattedDate = (date = new Date()) => {
  let month = "" + (date.getMonth() + 1),
    day = "" + date.getDate(),
    year = date.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("/");
};

export const getDefaultEventDate = (selectedDate) => {
  const currentDate = new Date();
  //const currentYear = currentDate.getFullYear();
  //const september8th = new Date(currentYear, selectedDate, selectedDate);
  return getApiFormattedDate(currentDate);
};

export const formatDateToYYYYMMDD = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}/${month}/${day}`;
};

export const getFullDateFormat = (dateString) => {
  const date = new Date(dateString);
  const options = {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  };
  const formattedDate = date.toLocaleString("en-US", options);
  return formattedDate;
};

//Apis constant
export const EVENTS_API_PATH = "/api/v1/events";

export const NEWS_TICKER = "/api/v1/news_ticker/text";

export const LOGIN_API_PATH = "/api/v1/auth/login";

export const GOOGLE_CALENDAR_BASE_URL = "https://calendar.google.com";

// External Sites URL
export const BROADWAY_SITE_URL = "https://prudentialstagesonbroadway.com";
