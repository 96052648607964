import client from "./axios";

import { LOGIN_API_PATH } from "../Constants/appConstants";

export const getAccessToken = async () => {
  const response = await client.post(null, LOGIN_API_PATH, {
    email: process.env.REACT_APP_ADMIN_USER,
    password: process.env.REACT_APP_ADMIN_PASS,
  });
  return response?.success ? response.data.token : null;
};
