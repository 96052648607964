import { Button, Image, Nav } from "react-bootstrap";
import siteLogo from "../../assets/images/curtain-up-logo.png";
import { HOME_PAGE } from "../../Constants/appConstants";
import { MENU_LIST } from "../../Modules/HomePage/homePageHelper";

import "./header.scss";
import { Link } from "react-router-dom";

const HeaderComponent = ({
  handleToggle,
  showNav,
  handleToGoBack,
  currentPath,
  handleShowMap,
  handleMenuClick,
}) => {
  return (
    <>
      <div className="cu-fa-header p-3 d-flex align-items-center justify-content-between bg-secondary">
        <Button variant="link">
          {currentPath === HOME_PAGE ? (
            <i className="bi bi-map fs-5" onClick={handleShowMap}></i>
          ) : (
            <i
              className="bi bi-arrow-left fs-5 map-icon"
              onClick={handleToGoBack}
            ></i>
          )}
        </Button>
        {/* <Image src={siteLogo} alt="site logo" className="cu-fa-logo" /> */}
        <Link to="/">
            <Image src={siteLogo} alt="site logo" className="cu-fa-logo" />
        </Link>
        <Button variant="link" onClick={handleToggle}>
          <i className="bi bi-list fs-3"></i>
        </Button>
      </div>
      {/* Overlay */}
      {showNav && (
        <div className="cu-fa-overlay bg-red" onClick={handleToggle}></div>
      )}
      {/* Off-canvas Navbar */}
      <div
        className={`cu-fa-offcanvas bg-primary p-4 scroll-y ${
          showNav && "open"
        }`}
      >
        <Button
          variant="link"
          className="text-white d-flex ms-auto p-1 mb-5"
          onClick={handleToggle}
        >
          <i className="bi bi-x-lg fs-5"></i>
        </Button>
        <div className="cu-fa-offcanvas-body">
          <Nav className="d-flex flex-column mb-4">
            {MENU_LIST?.map(({ icon, title, link, ga_title }, i) => (
              <Nav.Link
                key={i}
                href={link}
                className="fs-6 fw-semibold text-uppercase py-3 text-white d-flex gap-3 align-items-center"
                onClick={() => handleMenuClick(ga_title)}
              >
                <i className={`${icon} text-black fs-5`}></i>
                {title}
              </Nav.Link>
            ))}
          </Nav>
          <div className="menu-logo-wrap text-center py-4">
            <Image
              src={siteLogo}
              className="cu-menu-logo"
              alt="Curtain up logo"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderComponent;
