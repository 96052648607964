import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import EventCardComponent from "./eventCardComponent";

import { EVENT_DETAILS } from "../../Constants/appConstants";

export const EventCardConatiner = ({ eventList }) => {
  const navigate = useNavigate();

  //handle to show specifc event details
  const handleShowEventDetails = useCallback(
    (id) => {
      if (typeof window !== "undefined") {
        window.gtag("event", "event_details");
      }
      navigate(`${EVENT_DETAILS.replace(":id", id)}`);
    },
    [navigate]
  );

  return (
    <EventCardComponent
      eventList={eventList}
      handleShowEventDetails={handleShowEventDetails}
    />
  );
};
