export const convertISOToGoogleCalenderTimeFormat = timestamp => {
  const dateObj = new Date(timestamp);

  const year = dateObj.getUTCFullYear();
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1 and pad with '0'.
  const day = String(dateObj.getUTCDate()).padStart(2, "0");
  const hours = String(dateObj.getUTCHours()).padStart(2, "0");
  const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");
  const seconds = String(dateObj.getUTCSeconds()).padStart(2, "0");

  const customFormat = `${year}${month}${day}T${hours}${minutes}${seconds}`;

  return customFormat;
};
