import "./legalPage.scss";

export const Term = () => {
  return (
    <div className="cu-fa-legal-page d-flex flex-column flex-1 p-5 scroll-y">
      <h6 className="fs-md fw-semibold text-secondary ">
        Terms
      </h6>
      <p className="fs-sm fw-medium text-secondary">Yes, I agree The Prudential Insurance Company Of America,Pruco Securities,LLC and their affiliates ("Prudential") may contact me for marketing purposes by phone call, e-mail or text (including with an auto dialer and even if I've requested not to be contacted by Prudential or am on a do-not-call/contact list) at the phone number or email address listed above (message and data rates may apply). I also understand that consent isn't necessary to make an investment or purchase.</p>
      <p className="fs-sm fw-medium text-secondary">To continue without providing this consent please call 844-PRU-LINK (844-778-5465) Monday - Friday, 9 a.m -6 p.m .ET (except New York Stock ,Exchange holidays).</p>

    </div>
  );
};
